import * as React from 'react';

import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import Affiliate from '../components/Attribution/Affiliate';

// URL for Nikita Radker Redkar, tiktok influencer
const NikitaPage = () => {
  return (
    <>
      <Affiliate source="nikita" affiliate="nikita" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  );
};

export default NikitaPage;
